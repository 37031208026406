import React from 'react';
import { FaCheck } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const TradingPlans = () => {
  const plans = [
    {
      title: `Silver Plan`,
      subtitle: `Essential Trading`,
      amount: `$1,000`,
      features: [
        `Access to basic automated trading algorithms.`,
        `Trade execution on major cryptocurrency exchanges.`,
        `Portfolio tracking and performance analytics.`,
        `Email support with 24-hour response time.`,
        `Limited access to educational resources.`,
      ],
    },
    {
      title: `Gold Plan`,
      subtitle: `Advanced Trading`,
      amount: `$3,000 - $10,000`,
      features: [
        `All features of the Silver Plan.`,
        `Advanced trading algorithms with customizable parameters.`,
        `Priority trade execution for faster transactions.`,
        `Dedicated customer support with live chat.`,
        `Access to premium educational materials and webinars.`,
      ],
    },
    {
      title: `Platinum Plan`,
      subtitle: `Professional Trading`,
      amount: `$10,000`,
      features: [
        `All features of the Gold Plan.`,
        `Exclusive access to proprietary trading strategies.`,
        `Personalized portfolio management assistance.`,
        `Priority customer support with phone assistance.`,
        `Invitation to VIP events and workshops.`,
      ],
    },
    {
      title: `Diamond Plan`,
      subtitle: `Elite Trading`,
      amount: `$20,000`,
      features: [
        `All features of the Platinum Plan.`,
        `Unlimited access to all trading algorithms and strategies.`,
        `1-on-1 consultations with experienced traders.`,
        `Custom development of trading bots tailored to your needs.`,
        `Access to exclusive market insights and analysis reports.`,
      ],
    },
  ];

  return (
    <main className='content trading-plans-page'>
      <div className='title'>
        <h2>Trading Plans</h2>
      </div>
      <div className='grid'>
        {plans.map((plan, planIndex) => {
          const { amount, features, subtitle, title } = plan;
          return (
            <div className='card' key={planIndex}>
              <div className='title'>
                <h3>{title}</h3>
                <h4>{subtitle}</h4>
              </div>

              <div className='amount'>
                <h3>{amount}</h3>
              </div>

              <div className='list'>
                <h3>Features</h3>
                <ul>
                  {features.map((feature, featureIndex) => {
                    return (
                      <li key={featureIndex}>
                        <FaCheck /> {feature}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className='btn'>
                <Link to='/dashboard/deposit'>
                  <button className='linear'>Invest Now</button>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default TradingPlans;
