'use client';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper/modules';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import quote from '../../assets/images/quote.svg';
import customer1 from '../../assets/images/customer-1.jpg';
import customer2 from '../../assets/images/customer-2.jpg';
import customer3 from '../../assets/images/customer-3.jpg';
import customer4 from '../../assets/images/customer-4.jpg';
import customer5 from '../../assets/images/customer-5.jpg';

const CustomersReviews = () => {
  const customerReviews = [
    {
      customer: `Anna Jacobs`,
      job: `Experienced Trader`,
      review: `Growthsphere Investment' dedication to transparency and progress in the realm of automated trading is commendable. Their commitment to letting user opinions guide improvements sets them apart. While I haven't encountered significant issues, their willingness to evolve is noteworthy.`,
      profilePic: customer1,
    },
    {
      customer: `Jane Davidson `,
      job: `Novice Trader`,
      review: `Growthsphere Investment' approach of allowing users to share unfiltered feedback aligns perfectly with their automated trading ethos. As a beginner, I found their platform easy to navigate. No major concerns experienced, and the inclusion of a trade bonus for feedback underscores their user-centric approach.`,
      profilePic: customer2,
    },
    {
      customer: `Mike Stevens`,
      job: ` Novice Investor`,
      review: `Growthsphere Investment' choice to let user reviews speak volumes demonstrates their commitment to automated trading innovation. Their platform's user-friendliness stands out. While I haven't faced notable obstacles, their proactive pursuit of user feedback is commendable.`,
      profilePic: customer3,
    },
    {
      customer: `Linda Mitchell`,
      job: `Seasoned Crypto Enthusiast`,
      review: `Transparency is crucial, and Growthsphere Investment gets it right. Their unbiased stance by refraining from sharing their own views speaks volumes about their integrity. The platform has been reliable for me, though I haven't needed support so far.`,
      profilePic: customer4,
    },
    {
      customer: `Alex Robinson`,
      job: `Active Day Trader`,
      review: `Growthsphere Investment' decision to let user reviews speak for themselves is a standout feature. Their platform is intuitive to navigate. No major roadblocks encountered, but their proactive pursuit of user feedback is worth noting.`,
      profilePic: customer5,
    },
  ];

  return (
    <Swiper
      slidesPerView={1}
      breakpoints={{
        600: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      }}
      grid={{
        rows: 1,
      }}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Grid, Pagination]}
      className='customers-reviews-swiper'
    >
      {customerReviews.map((review, reviewIndex) => {
        return (
          <SwiperSlide key={reviewIndex}>
            <div className='quote'>
              <img src={quote} alt='' />
            </div>
            <p>{review.review}</p>
            <div className='profile-img'>
              <img src={review.profilePic} alt={review.customer} />
            </div>
            <h3>{review.customer}</h3>
            <h4>{review.job}</h4>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CustomersReviews;
