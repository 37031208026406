import React, { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { GoChevronRight } from 'react-icons/go';
import { Switch } from '@mantine/core';
import {
  UdatePersonalInfoForm,
  UpdatePasswordForm,
} from '../../../components/forms/Forms';

const AccountSettingsTabs = ({ userData }) => {
  const [activeTab, setActiveTab] = useState(`personal information`);

  return (
    <section className='account-settings-tabs'>
      {/* TABS */}
      <div className='tabs-container'>
        <ul className='tabs-list'>
          <li
            className={activeTab === `personal information` ? `active` : ''}
            onClick={() => setActiveTab(`personal information`)}
          >
            Personal Information
          </li>
          <li
            className={activeTab === `security information` ? `active` : ''}
            onClick={() => setActiveTab(`security information`)}
          >
            Security Information
          </li>
          <li
            className={activeTab === `notifications` ? `active` : ''}
            onClick={() => setActiveTab(`notifications`)}
          >
            Notifications
          </li>
        </ul>
      </div>

      {/* TAB DETAILS */}
      <div className='tab-details'>
        {activeTab === `personal information` ? (
          <PersonalInformation userData={userData} />
        ) : activeTab === `notifications` ? (
          <Notifications />
        ) : activeTab === `security information` ? (
          <SecurityInformation />
        ) : null}
      </div>
    </section>
  );
};

const PersonalInformation = ({ userData }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <section className='personal-info'>
        <div className='my-profile'>
          <h5>My Profile</h5>
        </div>

        <div className='info'>
          {/* name */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Full Name</h4>
              <p>{userData.fullname}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* email */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Email</h4>
              <p className='email'>{userData.email}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* phone number */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Phone Number</h4>
              <p>{userData.phone}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* address */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Address</h4>
              <p>{userData.address ? userData.address : `unknown`}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* country */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Country</h4>
              <p>{userData.country ? userData.country : `unknown`}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* marital status */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Marital Status</h4>
              <p>
                {userData.maritalStatus ? userData.maritalStatus : `unknown`}
              </p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* gender */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Gender</h4>
              <p>{userData.gender ? userData.gender : `unknown`}</p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>

          {/* investment goal */}
          <div className='item' onClick={open}>
            <div className='text'>
              <h4>Investment Goal</h4>
              <p>
                {userData.investmentGoal ? userData.investmentGoal : `unknown`}
              </p>
            </div>
            <div className='arrow'>
              <div className='icon'>
                <GoChevronRight />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* update info modal */}
      <Modal
        opened={opened}
        onClose={close}
        centered
        size='xl'
        title='Update Profile'
        className='profile-details-modal'
        overlayProps={{
          backgroundOpacity: 0.55,
        }}
      >
        <UdatePersonalInfoForm
          address={userData.address}
          country={userData.country}
          fullname={userData.fullname}
          maritalStatus={userData.maritalStatus}
          phone={userData.phone}
          gender={userData.gender}
          investmentGoal={userData.investmentGoal}
          dismissFunc={close}
        />
      </Modal>
    </>
  );
};

const SecurityInformation = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <section className='security-info'>
        <div className='title'>
          <h2>Security Settings</h2>
          <p>These settings are helps you keep your account secure.</p>
        </div>

        <div className='block'>
          <div className='item' onClick={open}>
            <div className='info'>
              <h3>Save my Activity Logs</h3>
              <p>
                You can save your all activity logs including unusual activity
                detected.
              </p>
            </div>
            <div className='btn'>
              <Switch defaultChecked />
            </div>
          </div>

          <div className='item' onClick={open}>
            <div className='info'>
              <h3>Change Password</h3>
              <p>Set a unique password to protect your account.</p>
            </div>
            <div className='btn'>
              <button className='linear' onClick={open}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        opened={opened}
        onClose={close}
        centered
        size='xl'
        title='Update Password'
        className='update-password-modal'
        overlayProps={{
          backgroundOpacity: 0.55,
        }}
      >
        <UpdatePasswordForm dismissFunc={close} />
      </Modal>
    </>
  );
};

const Notifications = () => {
  return (
    <>
      <section className='notifications-info'>
        <div className='block'>
          <div className='item'>
            <div className='title'>
              <h2>Security Alerts</h2>
              <p>You will get only those email notification what you want.</p>
            </div>

            <div className='notif'>
              <Switch defaultChecked />
              <p>Email me whenever encounter unusual activity</p>
            </div>
            <div className='notif'>
              <Switch />
              <p>Email me if new browser is used to sign in</p>
            </div>
          </div>

          <div className='item'>
            <div className='title'>
              <h2>News</h2>
              <p>You will get only those email notification what you want.</p>
            </div>

            <div className='notif'>
              <Switch defaultChecked />
              <p>Notify me by email about sales and latest news</p>
            </div>
            <div className='notif'>
              <Switch defaultChecked />
              <p>Email me about new features and updates</p>
            </div>
            <div className='notif'>
              <Switch defaultChecked />
              <p>Email me about tips on using account</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountSettingsTabs;
